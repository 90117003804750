:root {
  --white-smoke: #f2f2f2ff;
  --azul: #0f6fb5ff;
  --blue: #0064b3;
  --carolina-blue: #82b1d3ff;
  --steel-blue: #3482c0ff;
  --home-page-overlay: rgb(0, 40, 89);
  /* opacity is 0.57 */
  --oxford-blue: #0A1128;
}

* {
  font-family: Arial, Helvetica, sans-serif;
}

body {
  overflow-y: scroll;
  scroll-behavior: smooth;
  transition: none !important;
  /* disable transitions to the top of page */
}

/* overlay color mix */
/* background: rgba(0, 40, 88, 1);
        background: linear-gradient(0deg, rgba(0, 40, 88, 1) 53%, rgba(116, 152, 223, 0.8196078431372549) 75%); */

/********************************************Colors*/
.ccl-blue {
  color: var(--blue) !important;
}

.ccl-bg-blue {
  background-color: var(--oxford-blue) !important;
}

.ccl-bg-blue-light {
  background-color: var(--blue) !important;
}

.ccl-d-blue {
  color: var(--blue) !important;
}

.ccl-btn-blue {
  background-color: var(--blue) !important;
  color: white !important;
}

.ccl-btn-blue:hover {
  background-color: var(--oxford-blue) !important;
  transition: all 0.2s ease-in-out;
}

.header-g {
width:100%;
height:0;
border-top:20px solid var(--blue);
border-left:20px solid var(--blue);
position:absolute;
}

/*********************************************Defaults*/
.btn-transparent {
  background-color: transparent !important;
}

.btn-transparent:hover,
.btn-transparent:active {
  background-color: var(--steel-blue) !important;
  transition: all 0.2s ease-in-out;
}

.text-shadow {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1) !important;
}

/***888888888888888888888888888888888888888888Globals*/

/* Fade all non-hovered cards */
.stream-card-container:has(.stream-card:hover) .stream-card:not(:hover) {
  opacity: 0.75 !important;
  transition: all 0.2s ease-in-out;
}

.form-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(101, 196, 255, 0.35);
  box-shadow: 5px 5px 5px solid #65c4ff59;
  /* Adjust the opacity as needed */
  z-index: 999;
  /* Ensure it's above other elements */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-close {
  background-color: white !important;
  padding: 8px !important;
}




/************************************************Sizes */
@media screen and (max-width:480px) {
  .w-sm-75 {
    width: 75% !important;
  }
}