
/*===================================================================================== slide in from right */
.slide-in-from-right {
    animation: slideInFromRight 0.7s ease-in-out forwards !important;
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

/*===================================================================================== slide in from bottom */
.slide-in-from-bottom {
        animation: slideInFromBottom 0.4s ease-in-out forwards !important;
}
@keyframes slideInFromBottom {
    0% {
        opacity: 0;
        transform: translateY(200px);
    }

    20% {
        opacity: 0.2;
        transform: translateY(160px);
    }

    40% {
        transform: translateY(120px);
        opacity: 0.4;
    }

    60% {
        transform: translateY(80px);
        opacity: 0.6;
    }

    80% {
        transform: translateY(40px);
        opacity: 0.8;
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}